import * as CloseButtonToastStyles from "./close-button-toast.module.css"

import React from "react"
import classNames from "classnames"

const CloseButtonToast = props => (
    <button
        className={classNames("Toastify__close-button", CloseButtonToastStyles.close)}
        type="button"
        aria-label="close"
        onClick={props.closeToast}
    >
        +
    </button>
)

export default CloseButtonToast
