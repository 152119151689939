import "./src/styles/global.css"
import "./src/styles/icon-font.css"
import "./src/styles/print.css"
import "intersection-observer"
import "./src/styles/toast.css"

import WrapRoot from "./src/gatsby-api/wrap-root"

/* global exponea */

const onRouteUpdate = function(_) {
    // send page-view to Exponea
    setTimeout(() => {
        exponea.track("page_visit", {
            location: window.location.href,
            path: window.location.pathname,
        })
    }, 50)
}

const wrapRootElement = WrapRoot

export { onRouteUpdate, wrapRootElement }
